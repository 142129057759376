import React from 'react';

export enum SilhouetteType {
  None,
  Gear,
  Head,
  MusicNote,
  Camera,
  GameController
}

const paths = {};
paths[SilhouetteType.None] = <div />;
paths[SilhouetteType.Gear] = (
  <svg viewBox="0 0 121 121">
    <g transform="translate(-153.03 -268.95)">
      <g transform="matrix(39.803 0 0 39.803 63.558 116.51)">
        <path
          d="m74.031 0.0625l-7.625 13.376c-1.948-0.254-3.952-0.376-5.968-0.376-2.281 0-4.528 0.147-6.719 0.469l-8.469-13.062c-4.32 1.1292-8.454 2.7376-12.344 4.7498l2.656 15.187c-3.574 2.316-6.813 5.12-9.624 8.313l-14.813-4.5c-2.5493 3.609-4.7344 7.491-6.4688 11.625l11.688 9.906c-1.241 4.003-1.94 8.239-2.063 12.625l-14.218 6.187c0.35094 4.527 1.1952 8.929 2.4682 13.126l15.25-0.032c1.644 3.984 3.823 7.697 6.438 11.032l-6.938 13.972c3.091 3.17 6.508 6 10.219 8.43l11.688-9.9c3.713 1.95 7.719 3.4 11.937 4.28l3.563 15.19c1.898 0.18 3.804 0.28 5.75 0.28 2.519 0 4.999-0.17 7.437-0.47l2.656-15.19c4.199-0.94 8.162-2.45 11.844-4.47l12.406 9.35c3.641-2.54 6.999-5.46 9.999-8.72l-7.655-13.378c2.559-3.376 4.705-7.111 6.285-11.124l15.43-0.876c1.16-4.227 1.87-8.646 2.1-13.187l-14.35-5.281c-0.19-4.379-1-8.579-2.31-12.563l11.28-10.719c-1.84-4.079-4.13-7.929-6.78-11.468l-14.342 5.281c-2.863-3.143-6.136-5.869-9.75-8.125l1.812-15.531c-3.941-1.9002-8.114-3.3986-12.469-4.4065z"
          transform="matrix(.025123 0 0 .025123 2.248 3.8298)"
        />
      </g>
    </g>
  </svg>
);

paths[SilhouetteType.Head] = (
  <svg viewBox="0 0 1535.5566 1693.9089">
    <g transform="translate(-1077.7318,-229.8187)">
      <path d="M1633.0017,1876.7732c260.7216,0,521.4429,0,781.6719,0   c-32.8428-154.3505-72.1501-408.2728-55.2302-470.5089c27.8672-105.5531,117.9214-185.2161,151.761-269.8579   c172.1553-430.1788-38.2014-804.6875-513.9825-854.3834c-420.1136-43.8813-653.7932,194.6746-686.6362,441.1318   c-3.4811,26.3881,0,52.7762,12.4423,80.1613c-57.7153,90.1186-190.5698,223.0552-202.0098,286.7859   c-6.9684,38.3367,38.3108,51.7798,60.2063,65.2236c20.8989,6.9713,43.7847,1.9905,57.7152,18.92   c9.9514,12.4475-3.4811,33.3586-6.4702,48.7938c-0.4982,25.3925-30.3461,37.84-27.3632,61.2396   c2.9891,19.9164,19.9028,27.8827,35.328,40.3301c16.4214,12.9442-41.7982,37.8394-22.8917,60.7433   c19.4044,22.4041,47.77,48.2947,37.8126,80.1597c-14.4288,49.2911-42.2964,100.5735-19.4045,138.9115   c22.8917,37.8401,54.7323,60.2443,103.991,70.2002c77.1198,0,154.7438,1.5035,232.3616,1.5035   c26.865,23.4005,45.7714,99.0791,60.7046,200.6459H1633.0017z" />
    </g>
  </svg>
);

paths[SilhouetteType.MusicNote] = (
  <svg viewBox="0 0 100 100">
    <g>
      <path d="m93.161 0.071c-33.501-1.114-60.941 11.243-60.941 11.243l-0.02 62.709c-3.411-1.354-7.559-1.675-11.772-0.651-9.083 2.207-15.031 9.82-13.285 17.007s10.524 11.225 19.606 9.019c8.564-2.081 14.338-8.969 13.507-15.772v-46.855s19.404-6.784 44.573-8.485v34.849c-3.374-1.292-7.443-1.585-11.579-0.58-9.083 2.206-15.031 9.819-13.285 17.007 1.745 7.187 10.523 11.224 19.606 9.018 7.931-1.927 13.471-7.977 13.587-14.264l0.003 0.004v-74.249z" />
    </g>
  </svg>
);

paths[SilhouetteType.Camera] = (
  <svg viewBox="0 0 217.66085 173.87648">
    <defs>
      <clipPath id="cut-off-flash">
        <rect y="202.77477" x="92.161545" height="16.91545" width="95.282639" />
      </clipPath>
    </defs>
    <g transform="translate(-30.972436,-202.26931)">
      <rect
        ry="11.184659"
        y="202.77477"
        x="92.161545"
        height="53.792881"
        width="95.282639"
        clip-path="url(#cut-off-flash)"
      />
      <rect ry="32.262199" y="219.69022" x="32.262199" height="155.16582" width="215.08133" />
      <circle r="48.803452" cy="297.27313" cx="139.80286" fill="#FFFFFF" />
      <circle r="11.237184" cy="256.4501" cx="66.171921" fill="#FFFFFF" />
    </g>
  </svg>
);

paths[SilhouetteType.GameController] = (
  <svg viewBox="0 0 511.943 511.943">
    <path
      d="M492.814,240.733c-4.395-24.747-8.747-49.088-11.925-71.04c-8.427-58.347-27.733-95.296-57.365-109.824
            c-30.059-14.763-56.96-0.405-60.715,1.707c-50.624,31.403-88.107,28.437-88.256,28.395l-34.773-0.128l-2.155,0.107
            c-0.341,0.021-37.888,3.008-89.237-28.8c-2.987-1.685-29.973-16-59.968-1.28c-29.632,14.528-48.939,51.477-57.365,109.824
            c-3.179,21.952-7.531,46.293-11.925,71.04c-20.652,116.436-31.532,188.97,4.116,211.882c6.293,4.032,13.248,6.037,20.821,6.037
            c12.48,0,26.667-5.44,42.432-16.341c12.437-8.576,24.021-19.776,35.435-34.219l3.627-4.672
            c15.979-20.843,40.768-51.968,109.077-51.435h42.944c0.96,0,1.877-0.021,2.816-0.021c66.539,0,90.24,30.912,105.984,51.456
            l3.627,4.672c11.413,14.443,22.997,25.643,35.435,34.219c25.344,17.515,46.635,20.971,63.253,10.304
            C524.345,429.704,513.465,357.17,492.814,240.733z M213.305,245.32h-21.333v21.333c0,11.776-9.536,21.333-21.333,21.333
            s-21.333-9.557-21.333-21.333V245.32h-21.333c-11.797,0-21.333-9.557-21.333-21.333s9.536-21.333,21.333-21.333h21.333V181.32
            c0-11.776,9.536-21.333,21.333-21.333s21.333,9.557,21.333,21.333v21.333h21.333c11.797,0,21.333,9.557,21.333,21.333
            S225.102,245.32,213.305,245.32z M319.971,245.32c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333
            s21.333,9.557,21.333,21.333S331.747,245.32,319.971,245.32z M362.638,287.986c-11.776,0-21.333-9.557-21.333-21.333
            c0-11.776,9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333C383.971,278.429,374.414,287.986,362.638,287.986z
                M362.638,202.652c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333
            S374.414,202.652,362.638,202.652z M405.305,245.32c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333
            s21.333,9.557,21.333,21.333S417.081,245.32,405.305,245.32z"
    />
  </svg>
);

export const Silhouette = (props: { type: SilhouetteType }) => <div className="silhouette">{paths[props.type]}</div>;
